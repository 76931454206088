import { Button, Dialog, DialogActions, DialogTitle, Grid, Link, Stack } from "@mui/material";
import { PropsWithChildren, useState } from "react";
import PoweredBySkipifyIcon from "@icons/PoweredBySkipifyIcon";

export interface FooterProps {
  dataTestid?: string;
  onExit?(): void;
  customItemMd?: number;
  skipConfirmationOnExit?: boolean;
  px?: number;
}

export default function Footer({
  dataTestid,
  onExit,
  children,
  customItemMd = 5,
  skipConfirmationOnExit = false,
  px,
}: PropsWithChildren<FooterProps>) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialogExit = () => {
    onExit?.();
    handleClose();
  };

  return (
    <footer data-testid={dataTestid}>
      <Grid container my={2} px={px ?? 2}>
        {children && (
          <Grid item xs={12} md={customItemMd} marginBottom={2}>
            {children}
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" justifyContent="space-between">
            <PoweredBySkipifyIcon />
            {onExit && (
              <Link
                data-testid="footer-bottom-exit-button"
                role="button"
                variant="button"
                sx={{ fontSize: "12px" }}
                onClick={skipConfirmationOnExit ? onExit : handleOpen}
              >
                Exit
              </Link>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Are you sure you want to exit the checkout?</DialogTitle>
        <DialogActions>
          <Button fullWidth variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleDialogExit}
            data-testid="footer-confirmation-exit-button"
          >
            Exit
          </Button>
        </DialogActions>
      </Dialog>
    </footer>
  );
}

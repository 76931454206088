import { Order, Shipping, UpdateOrderRequest, ReviewOrderErrorTypes, ReviewOrderError } from "@models/order";
import { isMatch } from "lodash";
import { AdjustablePrice, FeeUnit, OrderCartItem, TaxInfo } from "skipify-types";

export const getTotalItemsQuantity = (items: OrderCartItem[]) => {
  return items.reduce((itemsCount, item) => itemsCount + item.quantity, 0);
};

export const getPriceFromAdjustablePrice = (adjustablePrice?: AdjustablePrice, TaxInfo?: TaxInfo) => {
  if (!adjustablePrice) return 0;
  return Number(adjustablePrice.adjustedAmount?.value ?? adjustablePrice.amount.value) + Number(TaxInfo?.value ?? 0);
};

export const getSelectedShippingOption = <T extends Pick<Shipping, "options" | "selected">>({
  options,
  selected,
}: T) => {
  return options?.find((option) => option.id === selected);
};

/**
 * Validates that the requested update is actually a new state for the order.
 *
 * @param order Current state of the order
 * @param update Requested updated state of the order
 * @returns True if order exists and if any property on the update is different from the current state.
 */
export const validateOrderUpdate = (order: Order | undefined, update: UpdateOrderRequest): boolean => {
  if (!order) {
    return false;
  }

  // remove any properties from baseUpdate that have different locations on the reviewed order object.
  const { tip, rewardPoints, discountCode, payments, ...baseUpdate } = update;

  if (payments && payments.length) {
    // First check length
    if (order?.payments?.length !== payments.length) {
      return true;
    }
    //then check all values
    const updateRefs = payments?.map((p) => p.ref);
    const different = order?.payments?.some((orderPayment) => {
      return !updateRefs.includes(orderPayment.ref);
    });
    if (different) return true;
  }
  // check the equality for non-baseUpdate properties above, early return if any do not match
  if (tip && (tip?.value !== order.pricing.tip?.value || tip?.uom !== order.pricing.tip?.uom)) {
    return true;
  }
  if (rewardPoints != null && rewardPoints !== order.rewardPoints) {
    // TODO: revisit if we change how rewards points are removed from orders
    return true;
  }

  // We allow discount code to be null since it's a way to remove it
  if ((discountCode || discountCode === null) && discountCode !== order.pricing.discount?.code) {
    // TODO: revisit if we change how discounts are removed from orders
    return true;
  }

  // an empty update isn't a valid update
  if (Object.keys(baseUpdate).length < 1) {
    return false;
  }

  // do a (potentially) more expensive compare for the rest of the order
  const match = isMatch(order, baseUpdate);
  return !match;
};

export const getReviewOrderErrors = (
  newOrder: Order,
  currentOrder: Order | undefined,
): ReviewOrderError | undefined => {
  if (newOrder?.shipping?.options?.length === 0 && !newOrder.configs?.noShippingRequired && !currentOrder?.paylinkId) {
    return {
      message: "Delivery options are not available for this shipping address. Please try a different shipping address",
      type: ReviewOrderErrorTypes.NO_SHIPPING_OPTION,
    };
  }
  return undefined;
};

/**
 * Filters the merchat fees configs for a specific fee type
 *
 * @param fees The merchant fees configs
 * @param type The fee type name to filter for
 * @returns A filtered fees array
 */
export const getFee = (fees: FeeUnit[], type: string) => {
  return fees?.filter((item) => item.feeType === type);
};
